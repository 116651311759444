/**
 * You can use this by defining this in layout:
 * 
 * <script src="@Url.Content("~/Scripts/inpulse/LocalizationHelper.js")" type="text/javascript"></script>
 * 
 *  var globalTranslations = new LocalizationHelper();
 * 
 * Then this in specific view:
 * 
 *  localTranslations = {
 *      "Sähkö": "@T("Sähkö")",
 *      "Kaukolämpö": "@T("Kaukolämpö")"
 *  };
 *
 *  window.globalTranslations.add(localTranslations);
 *  globalTranslations.translate("Sähkö");
 */
class LocalizationHelper {

    constructor() {

    }
    public translations = {

    };

    translate(text: string) {
        if (text in this.translations) {
            return this.translations[text]      
        }
        return text;
    }

    public add(trans: object) {
        $.extend(this.translations,trans);
    }

}